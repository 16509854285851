import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';

import './Navbar.css';

function Navbar() {
  const [activeSection, setActiveSection] = useState('Accueil');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const sections = {
        home: document.querySelector('.banner'),
        skills: document.querySelector('.skills'),
        projects: document.querySelector('.projects'),
        contact: document.querySelector('.contact'),
      };

      const menu = document.querySelector('.header-nav');

      const heroBannerHeight = 60;

      let isTopOfPage = scrollPosition < heroBannerHeight;

      setIsTopOfPage(isTopOfPage);

      for (const section in sections) {
        if (sections.hasOwnProperty(section)) {
          const sectionTop = sections[section].offsetTop;
          if (scrollPosition >= sectionTop - 65) {
            setActiveSection(section);
            isTopOfPage = false;
          }
        }
      }

      if (
        isTopOfPage ||
        (scrollPosition < heroBannerHeight && !isBurgerMenuOpen)
      ) {
        setActiveSection('Accueil');
        menu.classList.remove('sticky');
      } else if (
        scrollPosition >= heroBannerHeight &&
        !isMobile &&
        !isBurgerMenuOpen
      ) {
        menu.classList.add('sticky');
      } else {
        menu.classList.remove('sticky');
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, isBurgerMenuOpen]);

  const smoothScrollToSection = (sectionId) => {
    const section = document.querySelector(`.${sectionId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleBurgerMenuStateChange = (state) => {
    setIsBurgerMenuOpen(state.isOpen);
  };

  const handleNavLinkClick = (event, sectionId) => {
    event.preventDefault();
    setActiveSection(sectionId);
    smoothScrollToSection(sectionId);
    setIsBurgerMenuOpen(false);
  };

  return (
    <nav className={`header-nav ${isMobile ? 'mobile-menu' : 'desktop-menu'}`}>
      {isMobile ? (
        <Menu
          isOpen={isBurgerMenuOpen}
          onStateChange={handleBurgerMenuStateChange}
        >
          <a
            id="banner"
            className={`menu-item ${
              isBurgerMenuOpen
                ? ''
                : activeSection === 'home' && !isMobile
                ? 'active'
                : ''
            }`}
            href="/banner"
            onClick={(event) => handleNavLinkClick(event, 'banner')}
          >
            Accueil
          </a>
          <a
            id="skills"
            className={`menu-item ${
              isBurgerMenuOpen
                ? ''
                : activeSection === 'skills' && !isMobile
                ? 'active'
                : ''
            }`}
            href="/a-propos"
            onClick={(event) => handleNavLinkClick(event, 'skills')}
          >
            Mes compétences
          </a>
          <a
            id="projects"
            className={`menu-item ${
              isBurgerMenuOpen
                ? ''
                : activeSection === 'projects' && !isMobile
                ? 'active'
                : ''
            }`}
            href="/projets"
            onClick={(event) => handleNavLinkClick(event, 'projects')}
          >
            Mes projets
          </a>
          <a
            id="contact"
            className={`menu-item ${
              isBurgerMenuOpen
                ? ''
                : activeSection === 'contact' && !isMobile
                ? 'active'
                : ''
            }`}
            href="/contact"
            onClick={(event) => handleNavLinkClick(event, 'contact')}
          >
            Contact
          </a>
        </Menu>
      ) : null}
      {!isBurgerMenuOpen && !isMobile && (
        <ul>
          <li>
            <a
              className={activeSection === 'home' && !isMobile ? 'active' : ''}
              href="#banner"
              onClick={(event) => handleNavLinkClick(event, 'banner')}
            >
              Accueil
            </a>
          </li>
          <li>
            <a
              className={
                activeSection === 'skills' && !isMobile ? 'active' : ''
              }
              href="#skills"
              onClick={(event) => handleNavLinkClick(event, 'skills')}
            >
              Mes compétences
            </a>
          </li>
          <li>
            <a
              className={
                activeSection === 'projects' && !isMobile ? 'active' : ''
              }
              href="#projects"
              onClick={(event) => handleNavLinkClick(event, 'projects')}
            >
              Mes projets
            </a>
          </li>
          <li>
            <a
              className={`menu-item ${
                activeSection === 'contact' && !isMobile ? 'active' : ''
              } ${isTopOfPage ? 'contact-top' : ''}`}
              href="#contact"
              onClick={(event) => handleNavLinkClick(event, 'contact')}
            >
              Contact
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
