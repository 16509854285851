import './Skills.css';

function Skills() {
  return (
    <>
      <section className="skills">
        <h2>
          Mon parcours de <span className="coder-word">&lt;codeur/&gt;</span>
        </h2>
        <div className="skills-block">
          <p>
            <span className="quote-intro">"</span>Je me suis initié au
            développement web en suivant le cursus de{' '}
            <span className="skills-training">
              "Développeur d'application web"
            </span>{' '}
            à la Wild Code School sur le campus de Paris (4 mois de bootcamp
            suivis de 4 mois de stage).
            <br />
            <br />
            J'ai ensuite intégré le parcours{' '}
            <span className="skills-training">"Développeur Web"</span> de
            l'organisme de formation OpenClassrooms, où j'ai obtenu en décembre
            2023 la certification professionnelle de "Développeur Intégrateur
            Web" (RNCP niveau 5, bac+2), avec une spécialisation en JavaScript
            et React après 6 mois de formation.
            <br />
            <br />
            Depuis février 2024, je suis inscrit au cursus{' '}
            <span className="skills-training">
              "Développeur d'application PHP Symfony"
            </span>{' '}
            (1 an de formation) du même organisme afin de développer des
            compétences en backend et d'obtenir la certification professionnelle
            "Développeur Concepteur Logiciel" (RNCP niveau 6, bac+3/4).
            <span className="quote-intro">"</span>
          </p>
        </div>
        <div className="skills-icons">
          <i className="devicon-html5-plain-wordmark colored"></i>
          <i className="devicon-css3-plain-wordmark colored"></i>
          {/* <i className="devicon-sass-original colored"></i> */}
          <i className="devicon-javascript-plain colored"></i>
          <i className="devicon-react-original-wordmark colored"></i>
          {/* <i className="devicon-nodejs-plain-wordmark colored"></i>
          <i className="devicon-mongodb-plain-wordmark colored"></i> */}
          <i className="devicon-php-plain colored"></i>
          <i class="devicon-symfony-original colored symfony-icon"></i>
        </div>
      </section>
    </>
  );
}

export default Skills;
