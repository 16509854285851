import bookiImg from '../../assets/booki.png';
import kasaImg from '../../assets/kasa.png';
import menswearImg from '../../assets/menswear.png';
import monVieuxGrimoireImg from '../../assets/mon-vieux-grimoire.png';
import movieBoxImg from '../../assets/movie-box.png';
import rentYourBikeImg from '../../assets/rent-your-bike.png';
import sophieBluelImg from '../../assets/sophie-bluel.png';
import webAgencyImg from '../../assets/web-agency.png';
import ninaCarducciImg from '../../assets/nina-carducci.png';
import emilieForteroche from '../../assets/blog-emilie-forteroche.png';
import tromTroc from '../../assets/tom-troc.png';
import theArtBox from '../../assets/artbox.png';
import vehicloc from '../../assets/vehicloc.png';
import taskLinker from '../../assets/tasklinker.png';

export const ProjectList = [
  {
    id: 1,
    title: 'MovieBox',
    image: movieBoxImg,
    mainSkill: 'React',
    skills: ['JavaScript', 'React'],
    description:
      'Application de cinéma et de séries TV. Actualités et bandes-annonces.',
    link: 'http://moviebox-ohlbaum-julien.fr',
    altText: 'Image de présentation du site MovieBox',
    challengesAndSolutions:
      "Possibilité de visionner des bandes-annonces : utilisation des iframes. Moteur de recherche et suggestions des résultats : mise en place d'un système de moteur de recherche en temps réel avec useEffect(). Disponibilité des données : partage des données dans toute l'application en utilisant le Contexte. Filtrer le contenu par genre et date : utilisation pertinente des endpoints proposés par l'API.",
  },
  {
    id: 2,
    title: 'Mon vieux grimoire',
    image: monVieuxGrimoireImg,
    mainSkill: 'Node.js',
    skills: ['Node.js', 'Express', 'MongoDB', 'React'],
    description:
      "Application de notation de livres. Création d'un espace membre permettant aux utilisateurs d'ajouter, de modifier ou de supprimer un livre. Les membres peuvent attribuer des notes aux livres et consulter la note moyenne attribuée à chaque livre.",
    // link: 'https://johlbaum.github.io/mon-vieux-grimoire-deploy',
    altText: 'Image de présentation du site Mon vieux grimoire',
    challengesAndSolutions:
      "Espace Utilisateur Sécurisé : pour garantir la sécurité des comptes, j'ai intégré la librairie bcrypt pour la gestion des mots de passe. Sécurité des requêtes : j'ai mis en place un système d'authentification par token en utilisant la librairie JSON Web Token (JWT). Gestion d'Images : pour permettre l'ajout d'images au projet, j'ai utilisé la librairie multer. Conformité aux bonnes pratiques du Green Code : j'ai optimisé les performances de l'application en utilisant la librairie sharp pour le traitement de la taille et de la qualité des images.",
  },
  {
    id: 3,
    title: 'Portfolio de Sophie Bluel',
    image: sophieBluelImg,
    mainSkill: 'Vanilla Js',
    skills: ['Vanilla Js'],
    description:
      "Développement du front-end du portfolio de Sophie Bluel en JavaScript natif. Création d'un espace utilisateur lui permettant d'ajouter un nouveau projet ou de supprimer des projets.",
    // link: 'https://johlbaum.github.io/portfolio-sophie-bluel-deploy/',
    altText: 'Image de présentation du portfolio de Sophie Bluel',
    challengesAndSolutions:
      "Persistance des données : les données de chaque projet sont stockées dans une base de données. Sécurité : système d'authentification par token. Envoi d'une image : récupération des données du formulaire et utilisation de l'objet FormData(). Filtrage des projets par catégories : un filtre de catégorie apparaît si au moins un projet appartient à cette catégorie et est stocké en base de données. Traitement des données : validation des données des formulaires. Espace administrateur : stockage des informations d'identification dans le localStorage. Expérience utilisateur : ajout d'un spinner indiquant l'état de chargement d'un projet en base de données.",
  },
  {
    id: 4,
    title: 'Kasa',
    image: kasaImg,
    mainSkill: 'React',
    skills: ['React', 'Sass'],
    description:
      "Création du front-end de l'application Kasa, spécialisée dans la location d'appartements entre particuliers en France. Interface développée en Sass.",
    link: 'https://johlbaum.github.io/kasa/',
    altText: 'Image de présentation du site de Kasa',
    challengesAndSolutions: '',
  },
  {
    id: 5,
    title: 'Menswear',
    image: menswearImg,
    mainSkill: 'Node.js',
    skills: ['Node.js', 'Express', 'MongoDB', 'React'],
    description:
      "Plateforme e-commerce achats / ventes de vêtements pour homme sur le modèle du site Vinted.fr. L'utilisateur a la possibilité de vendre un produit et dispose d'un espace lui permettant de gérer ses ventes. API réalisée avec Node.js / Express. SGBD : MongoDB. J'ai utilisé la librairie Bcrypt pour la sécurisation des routes et le middleware Express Multer pour le transfert d'images du client vers le serveur.",
    link: 'http://menswear-ohlbaum-julien.fr/',
    altText: 'Image de présentation du site Menswear',
    challengesAndSolutions: '',
  },
  {
    id: 6,
    title: 'Portfolio de Nina Carducci',
    image: ninaCarducciImg,
    mainSkill: 'Vanilla Js',
    skills: ['Vanilla Js'],
    description:
      'Débogage et optimisation du site web de la photographe Nina Carducci.',
    link: 'https://johlbaum.github.io/nina-carducci/',
    altText: 'Image de présentation du site de Nina Carducci',
    challengesAndSolutions:
      "Optimisation du site en termes de performance, de SEO et d'accessibilité : réalisation d'audits pour mesurer les principaux indicateurs. Traitement des images. Optimisation des fichiers et de leur chargement. Site entièrement accessible au clavier. Mise en place du référencement local. Rédaction d'un rapport détaillé afin de démontrer l'efficacité des améliorations apportées. Débogage de la galerie : suppression du code jQuery. Création d'une galerie fonctionnelle en JavaScript natif pour limiter l'utilisation de bibliothèques externes.",
  },
  {
    id: 7,
    title: 'Rent your bike',
    image: rentYourBikeImg,
    mainSkill: 'Vanilla Js',
    skills: ['Vanilla Js'],
    description:
      "Développement d'une application simulant la réservation de vélos dans la ville de Lyon par le biais d’une carte interactive. Projet entièrement codé en orientée objet et réalisé à l’aide des API Leftlet, Canvas et WebStorage.",
    link: 'http://rentyourbike-ohlbaum-julien.fr/',
    altText: 'Image de présentation du site de Nina Carducci',
    challengesAndSolutions: '',
  },
  {
    id: 8,
    title: 'Booki',
    image: bookiImg,
    mainSkill: 'Intégration',
    skills: ['HTML', 'CSS'],
    description:
      "Projet d'intégration du site Booki permettant aux usagers de trouver des hébergements et des activités dans la ville de leur choix.",
    link: 'https://johlbaum.github.io/booki/',
    altText: 'Image de présentation du site de Booki',
    challengesAndSolutions: '',
  },
  {
    id: 9,
    title: 'Web agency',
    image: webAgencyImg,
    mainSkill: 'Intégration',
    skills: ['HTML', 'CSS'],
    description:
      'Refonte du site de la WebAgency, agence fictive spécialisée dans la réalisation de sites web. Projet d’intégration effectué à partir d’une maquette communiquée par le graphiste de l’agence.',
    link: 'http://webagency-ohlbaum-julien.fr/',
    altText: 'Image de présentation du site de la Web agency',
    challengesAndSolutions: '',
  },
  {
    id: 10,
    title: 'Blog Emilie Forteroche',
    image: emilieForteroche,
    mainSkill: 'PHP',
    skills: ['PHP', 'MVC'],
    description:
      "Mise à jour du blog d'Emilie Forteroche. Ajout d'une page de monitoring pour les articles dans la partie admin.",
    link: 'https://github.com/johlbaum/PHP-blog-emilie-forteroche',
    altText: "Image de présentation du blog d'Emilie Forteroche",
    challengesAndSolutions: '',
  },
  {
    id: 11,
    title: 'Tom Troc',
    image: tromTroc,
    mainSkill: 'PHP',
    skills: ['PHP', 'MVC'],
    description:
      'L’association Tom Troc vise à mettre en relation des particuliers qui souhaitent échanger des livres afin de donner une seconde vie aux livres qui ne sont plus  utilisés. Objectif du projet : créer une plateforme conviviale où les membres peuvent facilement se connecter, communiquer et organiser des échanges de livres en toute simplicité.',
    link: 'https://github.com/johlbaum/p6-tom-troc',
    altText: 'Image de présentation du site Tom Troc',
    challengesAndSolutions: '',
  },
  {
    id: 12,
    title: 'The Artbox',
    image: theArtBox,
    mainSkill: 'PHP',
    skills: ['PHP'],
    description:
      "Refonte du site de The ArtBox, une galerie d'art contemporain. Mise en place d'une base de données pour stocker les œuvres, ainsi qu’un formulaire de création de nouvelles œuvres.",
    link: 'https://github.com/johlbaum/p4-the-artbox',
    altText: 'Image de présentation du site The artbox',
    challengesAndSolutions: '',
  },
  {
    id: 13,
    title: 'Vehicloc',
    image: vehicloc,
    mainSkill: 'Symfony',
    skills: ['Symfony'],
    description:
      'Refonte du site de l’entreprise VéhiLoc, spécialisée dans la location de voiture. Migration du code de base en PHP natif vers Symfony. Ajout de nouvelles voitures et suppression des voitures existantes.',
    link: 'https://github.com/johlbaum/vehicloc',
    altText: 'Image de présentation du site Vehicloc',
    challengesAndSolutions: '',
  },
  {
    id: 14,
    title: 'TaskLinker',
    image: taskLinker,
    mainSkill: 'Symfony',
    skills: ['Symfony'],
    description:
      "Développement d'une application de gestion de projet. Mise en place d'un espace utilisateur sécurisé, création d'un système d'authentification et de gestion des rôles.",
    link: 'https://github.com/johlbaum/tasklinker-v2',
    altText: "Image de présentation de l'application TaskLinker",
    challengesAndSolutions: '',
  },
];
