import React from 'react';
import { useState } from 'react';
import ProjectItem from '../Project/ProjectItem.jsx';
import Filters from '../Filters/Filters.jsx';
import { motion, AnimatePresence } from 'framer-motion';

import './Projects.css';

function Projects() {
  const [filtered, setFiltered] = useState([]);

  return (
    <section className="projects">
      <div className="wrapper-projects-list">
        <div className="projects-left">
          <h2 className="projects-header">Mes projets</h2>
          <Filters setFiltered={setFiltered} />
        </div>
        <motion.div layout className="projectList">
          <AnimatePresence>
            {filtered.map((project, index) => {
              return (
                <React.Fragment key={`${project}-${index}`}>
                  <ProjectItem
                    id={project.id}
                    title={project.title}
                    image={project.image}
                    description={project.description}
                    skills={project.skills}
                    link={project.link}
                    altText={project.altText}
                    challengesAndSolutions={project.challengesAndSolutions}
                  />
                </React.Fragment>
              );
            })}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
}

export default Projects;
