import React, { useState, useEffect } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import BgImage from '../../assets/header-bg.jpeg';
import { AiFillGithub } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';

import './Banner.css';

function Banner() {
  const images = [BgImage];
  const [backgroundOpacity, setBackgroundOpacity] = useState(0.6);
  const [headerText, setHeaderText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const headerSentence = 'Julien Ohlbaum.';
  const bodySentence = 'Je réalise des sites et des applications web.';
  const speed = 55;
  const [cursorPosition, setCursorPosition] = useState(0);
  const [headerCursorVisible, setHeaderCursorVisible] = useState(true);
  const [bodyCursorVisible, setBodyCursorVisible] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= headerSentence.length) {
        setHeaderText(headerSentence.substring(0, currentIndex));
        currentIndex++;
      } else {
        setHeaderCursorVisible(false);
        currentIndex = 0;
        clearInterval(interval);
        const bodyInterval = setInterval(() => {
          if (currentIndex <= bodySentence.length) {
            setBodyText(bodySentence.substring(0, currentIndex));
            currentIndex++;
            setBodyCursorVisible(true);
          } else {
            clearInterval(bodyInterval);
          }
        }, speed);
      }
    }, speed);
  }, []);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursorPosition((prevPosition) => {
        if (
          (!headerCursorVisible && !bodyCursorVisible) ||
          cursorPosition * 20 >= bodyText.length * 20
        ) {
          clearInterval(cursorInterval);
          return prevPosition;
        }
        return prevPosition + 1;
      });
    }, speed);

    return () => {
      clearInterval(cursorInterval);
    };
  }, [headerCursorVisible, bodyCursorVisible, cursorPosition, bodyText]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const maxScroll = windowHeight / 2.5;

      const scrollPercentage = Math.min(
        1,
        Math.max(
          0.6,
          0.6 +
            (1 -
              Math.exp(-(scrollPosition - maxScroll / 2) / (maxScroll / 1.8))) *
              0.6,
        ),
      );

      setBackgroundOpacity(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ParallaxBanner
        layers={[
          {
            image: images[0],
            speed: -35,
          },
        ]}
        className="parallax-overlay"
        style={{
          '--background-opacity': backgroundOpacity,
        }}
      ></ParallaxBanner>

      <div className="content">
        <div className="left-block">
          <p className="hello">
            <span className="hello-word">Bonjour</span>, je suis
          </p>
          <div className="typing-animation">
            <h1>
              {headerText}
              {headerCursorVisible && (
                <span
                  className="cursor"
                  style={{ left: `${cursorPosition * 20}px` }}
                ></span>
              )}
            </h1>
            <p className="content-description">
              {bodyText}
              {bodyCursorVisible && (
                <span
                  className="cursor"
                  style={{ left: `${cursorPosition * 20}px` }}
                ></span>
              )}
            </p>
            <hr className="separation-line" />
            <div className="social-links">
              <a
                href="https://github.com/johlbaum"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
              <a
                href="https://www.linkedin.com/in/julienohlbaum"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillLinkedin />
              </a>
            </div>
          </div>
        </div>
        <div className="right-block">
          <p>
            <span className="quote-intro">"</span>Je crée des interfaces
            utilisateur responsives en utilisant <strong>React.js</strong>.
            <br />
            <br />
            Je <strong>modélise des bases de données </strong> et développe des
            sites/applications web en <strong>PHP</strong> natif et en{' '}
            <strong>Symfony.</strong>
            <br />
            <br />
            J'intègre des <strong>espaces utilisateurs sécurisés.</strong>
            <br />
            <br />
            Je crée des <strong>API REST</strong> en Symfony.
            <span className="quote-intro">"</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Banner;
